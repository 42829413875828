import React from 'react'
import { Link } from 'gatsby'

const SiteMapPage = () => {
  return (
    <main className="siteMapPage">
      <div className="page-section">
        <h1 className="sitemap-heading">Site map</h1>
        <div className="row">
          <div className="col-lg-6 col-xl-4 category-col">
            <div className="category-name">What Is the Aidia System?</div>
            <Link to="/how-aidia-works">How Aidia Works</Link>
            <Link to="/how-to-get-aidia">How to Get Aidia</Link>
            <Link to="/support-services">Support Services</Link>
            <Link to="/faqs">FAQs</Link>
          </div>
          <div className="col-lg-6 col-xl-4 category-col">
            <div className="category-name">Why Partner With Us?</div>
            <Link to="/biopharma">BioPharma</Link>
            <Link to="/pharmacies-and-healthsystems">Pharmacies &amp; Health Systems</Link>
            <Link to="/healthplans-and-payers">Health Plans & Payers</Link>
            <Link to="/researchers">Researchers</Link>
          </div>
          <div className="col-lg-6 col-xl-4 category-col">
            <div className="category-name">Other</div>
            <Link to="/the-adherence-problem">The Adherence Problem</Link>
            <Link to="/evidence">Evidence</Link>
            <Link to="/about-us">About Us</Link>
            <Link to="/contact-us">Contact Us</Link>
          </div>
        </div>
      </div>
    </main>
  )
}

export default SiteMapPage
